import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {listUsers} from "../../graphql/queries";
import LoadingAnimation from "../../components/LoadingAnimation";
import UserTable from "./userTable";


const AllUser = () => {
    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [user, setUser] = useState({
        username: undefined
    })

    let getData = async () => {
        const response: any = await API.graphql({
            query: listUsers,
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response.data.listUsers.items
    }

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])

    useEffect(() => {
        if (user.username) {
            getData().then(r => {
                setLoader(false)
                console.log("res",r)
                setData(r.map((d : any)=> {
                    console.log('allRecord::',r)
                    return {
                        firstName : d.firstName,
                        lastName : d.lastName,
                        fullName : d.fullName,
                        userName : d.userName,
                        userType : d.UserType,
                        email : d.email,
                        isActive : d.isActive.toString(),
                        isVerified : d.isVerified.toString(),
                        id : d.id,
                        isAdmin : d.isAdmin
                    }
                }))
            })
        }
    }, [user.username])



    return (
        <div>
            {allData.length===0 ?  ( loader ? <LoadingAnimation/> : <h3 >No data found</h3>) : (<UserTable data={allData}/>)}
        </div>
    )
}

export default AllUser