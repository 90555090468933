import {Auth} from "aws-amplify";
import {useEffect, useState} from "react";
import { Box, Chip, Grid, Paper, Stack, Typography} from "@mui/material";


const AdminInfo=()=>{

    const [profile, setProfile] = useState({
        sub: undefined,
        given_name: undefined,
        email: undefined,
        email_verified: undefined,
        phone_number_verified: undefined,
        family_name: undefined,
        'custom:userType' : "",
        phone_number : undefined,
        userType : undefined

    })

    useEffect(()=> {
        Auth.currentUserInfo().then(r => setProfile(r.attributes))
    },[])

    console.log("profile",profile)

    return(
        <div>
            <Box>
                <Paper sx={{p:5}}>
                    <Grid>
                        <Grid sm={24}>
                            <Stack direction="row" spacing={1}>
                                Type : {profile['custom:userType']}
                            </Stack>
                        </Grid>
                        <Grid sm={24}>
                            <Stack direction="row" spacing={1}>
                                {profile['custom:userType']} ID : <Chip label={profile.sub} color="success" size="small" variant="outlined"/>
                            </Stack>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                {profile['custom:userType']} Name : {profile.given_name} {profile.family_name}
                            </Typography>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                {profile['custom:userType']} Email : {profile.email}
                            </Typography>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                {profile['custom:userType']} Phone Number : {profile?.phone_number}
                            </Typography>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                {profile['custom:userType']} Email Verified? : {profile.email_verified ? (
                                <Chip label='Verified' color="success" size="small"  variant="outlined"/>) : (
                                <Chip label='Not Verified' color="success" size="small" variant="outlined"/>)}
                            </Typography>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                {profile['custom:userType']} Phone Number Verified? : <Chip label='Not Verified' color="success" size="small" variant="outlined"/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    )
}
export default AdminInfo;