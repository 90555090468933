// assets
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined
} from '@ant-design/icons';

// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Payments',
    type: 'group',
    children: [
        {
            id: 'admin',
            title: 'Admin Info',
            type: 'item',
            url: '/admin',
            icon: icons.BgColorsOutlined
        },
        {
            id: 'all',
            title: 'All User/Merchant/Admin',
            type: 'item',
            url: '/all-user',
            icon: icons.AntDesignOutlined,
            breadcrumbs: false
        },
        {
            id: 'approve',
            title: 'Approved User/Merchant',
            type: 'item',
            url: '/approve-user',
            icon: icons.AntDesignOutlined,
            breadcrumbs: false
        },
        {
            id: 'pending',
            title: 'Pending Merchant',
            type: 'item',
            url: '/pending_user',
            icon: icons.AntDesignOutlined,
            breadcrumbs: false
        },
        {
            id: 'payment',
            title: 'Payment Info',
            type: 'item',
            url: '/payment-info',
            icon: icons.FontSizeOutlined
        },
        {
            id: 'recurring',
            title: 'Payment Schedule',
            type: 'item',
            url: '/recurring-payment-info',
            icon: icons.BarcodeOutlined
        },
        {
            id: 'refund',
            title: 'Refund',
            type: 'item',
            url: '/refund-info',
            icon: icons.AntDesignOutlined,
            breadcrumbs: false
        },
        {
            id: 'Manual',
            title: 'Manual Payment',
            type: 'item',
            url: '/demo-payment',
            icon: icons.BgColorsOutlined
        },
        {
            id: 'recurring-Manual',
            title: 'Manual Schedule Payment',
            type: 'item',
            url: '/demo-schedule-payment',
            icon: icons.BgColorsOutlined
        },
    ]
};

export default utilities;
