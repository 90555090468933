import {API, Auth} from "aws-amplify";
import {searchPayments} from "../../graphql/queries";
import {useEffect, useState} from "react";
import PaymentInfo from "./PaymentInfo";
import moment from "moment";
import LoadingAnimation from "../../components/LoadingAnimation";

const PayInfo = () => {

    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [user, setUser] = useState({
        username: undefined
    })

    // eslint-disable-next-line no-unused-vars
    const [search, setSearch] = useState(null)

    const [limit, setLimit] = useState(5)
    const [from, setFrom] = useState(5)

    let updateFrom = (l: number, f: number) => {
        setLimit(l)
        setFrom(f)
    }

    let searchParam = (param: any) => {
        console.log('param', param)
        setSearch(param)
    }

    console.log('limit, page', limit, from)

    let getData = async () => {
        const response: any = await API.graphql({
            query: searchPayments,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {filter: {PaymentType: {eq: "Payment"}}}
        })

        console.log('response', response.data.searchPayments.items)
        console.log('response', response.data)
        return response.data.searchPayments
    }

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])

    useEffect(() => {
        if (user.username) {
            getData().then(res => {
                let total = res.total
                console.log('res', res)
                setLoader(false)
                setData(res.items.map((d: any) => {
                    let ExpiryDate = JSON.parse(d.ExpiryDate)
                    console.log("d",d)
                    return {
                        date: moment(d.tranTime ? d.tranTime : d.createdAt).format('DD/MM/YYYY, h:mm a').toString(),
                        transactionType: d.transactionType,
                        transactionStatus: d.transactionStatus,
                        taTotal: `${d.taTotal} (${d.taCurrency})`,
                        approveTotal: d.approveTotal ? `${d.approveTotal} (${d.approveCurrency})` : 'Not approved',
                        transactionId: d.ipgTransactionId,
                        isRefund: d.isRefund,
                        id: d.id,
                        merchantID: d.merchantID,
                        merchantName: d.merchant?.fullName,
                        senderName : d.paymentBy?.fullName || d.paymentByEmail || d.paymentByNumber,
                        senderType: d.paymentBy?.UserType || "User",
                        transactionOrigin: d.transactionOrigin,
                        requestType: d.requestType,
                        orderId: d.orderId,
                        apiTraceId: d.apiTraceId,
                        //customApiKey: d.merchant.customApiKey,
                        userName: d.merchant.userName,
                        total: total,
                        CardNumber: d.CardNumber,
                        month: ExpiryDate.month,
                        year: ExpiryDate.year,
                        SecurityCode: d.SecurityCode,
                        paymentDescription: d.paymentDescription,
                        requestDetail : d.requestDetail,
                        paymentTitle:d.paymentTitle,
                        allInfo: d.responseDetail,
                        userType: d.merchant.UserType,
                        paymentByID : d.paymentByID,
                        paymentByEmail : d.paymentByEmail,
                        paymentByNumber : d.paymentByNumber,
                    }
                }))
            })

        }


    }, [user.username, limit])

    console.log('allData',allData)

    return (
        <>
            {loader ? <LoadingAnimation/> :
                <PaymentInfo limit={limit} from={from} data={allData} updateFrom={updateFrom}
                             searchParam={searchParam}/>}
        </>
    )
}

export default PayInfo