import {useEffect, useState} from "react";
import {Container, Divider, Grid, Paper, Typography} from "@mui/material";
import LoadingAnimation from "../../components/LoadingAnimation";

const UserDetails = () => {
    const [userData] = useState({
        firstName: undefined,
        lastName: undefined,
        fullName: undefined,
        userName: undefined,
        email: undefined,
        isActive: undefined,
        isVerified: undefined,
        customApiKey: undefined,
        id: undefined
    })
    const [load, setLoad] = useState(false)

    const getUserInfo = async () => {
        // const response: any = await API.graphql({
        //     query: getUser,
        //     authMode: "AMAZON_COGNITO_USER_POOLS",
        //     variables: {id: params.id}
        // })
        // return response.data.getUser
    }
    useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        getUserInfo().then(r => {
            // setUserData(r)
            setLoad(true)
        })
    }, [])

    const UserDataView = () => {
        return (
            <Paper elevation={3} sx={{p:2}}>
                <Container maxWidth={'lg'}>
                    <Typography sx={{textAlign:'center'}} variant={'h5'}>
                        Detailed Info
                    </Typography>
                    <Divider variant={'middle'}/>
                    <Grid container sx={{textAlign:"center", mt: 2}}>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                First Name
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                {
                                    userData.firstName
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{textAlign:"center", mt: 2}}>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                Last Name
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                {
                                    userData.lastName
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{textAlign:"center", mt: 2}}>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                Full Name
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                {
                                    userData.fullName
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{textAlign:"center", mt: 2}}>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                User Name
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                {
                                    userData.userName
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{textAlign:"center", mt: 2}}>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                Email
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                {
                                    userData.email
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{textAlign:"center", mt: 2}}>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                IsActive
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                userData.isActive
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container sx={{textAlign:"center", mt: 2}}>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                IsVerified
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant={'body1'}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>
                                {
                                    userData.isVerified
                                }
                            </Typography>

                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        )
    }
    const Loading = () => {
        return (
            <>
                <LoadingAnimation/>
            </>
        )
    }

    console.log("load",load)
    return (
        <>
            {load ? (<UserDataView/>) : (<Loading/>)}
        </>
    )
}

export default UserDetails