/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchAccountVarifications = /* GraphQL */ `
  query SearchAccountVarifications(
    $filter: SearchableAccountVarificationFilterInput
    $sort: [SearchableAccountVarificationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAccountVarificationAggregationInput]
  ) {
    searchAccountVarifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        merchantID
        requestType
        paymentCard
        cardDetails
        requestStatus
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchOAuthAppPermissions = /* GraphQL */ `
  query SearchOAuthAppPermissions(
    $filter: SearchableOAuthAppPermissionFilterInput
    $sort: [SearchableOAuthAppPermissionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOAuthAppPermissionAggregationInput]
  ) {
    searchOAuthAppPermissions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        oAuthAppID
        userID
        isPermited
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchAccountInformations = /* GraphQL */ `
  query SearchAccountInformations(
    $filter: SearchableAccountInformationFilterInput
    $sort: [SearchableAccountInformationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAccountInformationAggregationInput]
  ) {
    searchAccountInformations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        merchantID
        requestType
        paymentCard
        billingAddress
        cardDetails
        requestStatus
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPayments = /* GraphQL */ `
  query SearchPayments(
    $filter: SearchablePaymentFilterInput
    $sort: [SearchablePaymentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePaymentAggregationInput]
  ) {
    searchPayments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        requestID
        requestType
        PaymentType
        taTotal
        taCurrency
        CardNumber
        SecurityCode
        ExpiryDate
        currencyConversion
        clientRequestId
        apiTraceId
        ipgTransactionId
        transactionType
        transactionTime
        approveTotal
        approveCurrency
        transactionStatus
        schemeTransactionId
        responseCode
        responseMessage
        authorizationCode
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        startDate
        numberOfPayments
        maximumFailures
        invoiceNumber
        purchaseOrderNumber
        transactionOrigin
        dynamicMerchantName
        frequency
        paymentMethod
        clientLocale
        paymentTitle
        paymentDescription
        orderId
        storeId
        runCount
        state
        comments
        isDeleted
        createdAt
        tranTime
        isRefund
        refundTotal
        cardHolderName
        billing
        address
        istds
        refund {
          nextToken
        }
        refundCurrency
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchRefunds = /* GraphQL */ `
  query SearchRefunds(
    $filter: SearchableRefundFilterInput
    $sort: [SearchableRefundSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRefundAggregationInput]
  ) {
    searchRefunds(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        paymentID
        payment {
          id
          merchantID
          requestID
          requestType
          PaymentType
          taTotal
          taCurrency
          CardNumber
          SecurityCode
          ExpiryDate
          currencyConversion
          clientRequestId
          apiTraceId
          ipgTransactionId
          transactionType
          transactionTime
          approveTotal
          approveCurrency
          transactionStatus
          schemeTransactionId
          responseCode
          responseMessage
          authorizationCode
          statusCode
          statusDetail
          requestDetail
          responseDetail
          requestDate
          startDate
          numberOfPayments
          maximumFailures
          invoiceNumber
          purchaseOrderNumber
          transactionOrigin
          dynamicMerchantName
          frequency
          paymentMethod
          clientLocale
          paymentTitle
          paymentDescription
          orderId
          storeId
          runCount
          state
          comments
          isDeleted
          createdAt
          tranTime
          isRefund
          refundTotal
          cardHolderName
          billing
          address
          istds
          refundCurrency
          paymentByID
          paymentByEmail
          paymentByphone
          updatedAt
        }
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        refundTitle
        refundDescription
        requestID
        orderID
        requestType
        refundTotal
        refundCurrency
        refundRequset
        refundResponse
        createdAt
        tranTime
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccountVarification = /* GraphQL */ `
  query GetAccountVarification($id: ID!) {
    getAccountVarification(id: $id) {
      id
      merchantID
      requestType
      paymentCard
      cardDetails
      requestStatus
      statusCode
      statusDetail
      requestDetail
      responseDetail
      requestDate
      createdAt
      updatedAt
    }
  }
`;
export const listAccountVarifications = /* GraphQL */ `
  query ListAccountVarifications(
    $filter: ModelAccountVarificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountVarifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantID
        requestType
        paymentCard
        cardDetails
        requestStatus
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOAuthAppPermission = /* GraphQL */ `
  query GetOAuthAppPermission($id: ID!) {
    getOAuthAppPermission(id: $id) {
      id
      oAuthAppID
      userID
      isPermited
      createdAt
      updatedAt
    }
  }
`;
export const listOAuthAppPermissions = /* GraphQL */ `
  query ListOAuthAppPermissions(
    $filter: ModelOAuthAppPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOAuthAppPermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        oAuthAppID
        userID
        isPermited
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccountInformation = /* GraphQL */ `
  query GetAccountInformation($id: ID!) {
    getAccountInformation(id: $id) {
      id
      merchantID
      requestType
      paymentCard
      billingAddress
      cardDetails
      requestStatus
      statusCode
      statusDetail
      requestDetail
      responseDetail
      requestDate
      createdAt
      updatedAt
    }
  }
`;
export const listAccountInformations = /* GraphQL */ `
  query ListAccountInformations(
    $filter: ModelAccountInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantID
        requestType
        paymentCard
        billingAddress
        cardDetails
        requestStatus
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      merchantID
      merchant {
        id
        customApiKey
        userName
        owner
        UserType
        firstName
        lastName
        profilePhotoActive
        fullName
        email
        phone
        gender
        dobDay
        dobMonth
        dobYear
        countryID
        stateID
        Address
        homestateCountryID
        homestateID
        homestateCity
        homeAddress
        emailNotification
        pushNotification
        hasAcceptedCockie
        isAccountVerified
        isActive
        isTFAActive
        isVerified
        isDenied
        isAdmin
        signUpDate
        createdAt
        updatedAt
      }
      requestID
      requestType
      PaymentType
      taTotal
      taCurrency
      CardNumber
      SecurityCode
      ExpiryDate
      currencyConversion
      clientRequestId
      apiTraceId
      ipgTransactionId
      transactionType
      transactionTime
      approveTotal
      approveCurrency
      transactionStatus
      schemeTransactionId
      responseCode
      responseMessage
      authorizationCode
      statusCode
      statusDetail
      requestDetail
      responseDetail
      requestDate
      startDate
      numberOfPayments
      maximumFailures
      invoiceNumber
      purchaseOrderNumber
      transactionOrigin
      dynamicMerchantName
      frequency
      paymentMethod
      clientLocale
      paymentTitle
      paymentDescription
      orderId
      storeId
      runCount
      state
      comments
      isDeleted
      createdAt
      tranTime
      isRefund
      refundTotal
      cardHolderName
      billing
      address
      istds
      refund {
        items {
          id
          paymentID
          merchantID
          paymentByID
          paymentByEmail
          paymentByphone
          refundTitle
          refundDescription
          requestID
          orderID
          requestType
          refundTotal
          refundCurrency
          refundRequset
          refundResponse
          createdAt
          tranTime
          updatedAt
        }
        nextToken
      }
      refundCurrency
      paymentByID
      paymentBy {
        id
        customApiKey
        userName
        owner
        UserType
        firstName
        lastName
        profilePhotoActive
        fullName
        email
        phone
        gender
        dobDay
        dobMonth
        dobYear
        countryID
        stateID
        Address
        homestateCountryID
        homestateID
        homestateCity
        homeAddress
        emailNotification
        pushNotification
        hasAcceptedCockie
        isAccountVerified
        isActive
        isTFAActive
        isVerified
        isDenied
        isAdmin
        signUpDate
        createdAt
        updatedAt
      }
      paymentByEmail
      paymentByphone
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        requestID
        requestType
        PaymentType
        taTotal
        taCurrency
        CardNumber
        SecurityCode
        ExpiryDate
        currencyConversion
        clientRequestId
        apiTraceId
        ipgTransactionId
        transactionType
        transactionTime
        approveTotal
        approveCurrency
        transactionStatus
        schemeTransactionId
        responseCode
        responseMessage
        authorizationCode
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        startDate
        numberOfPayments
        maximumFailures
        invoiceNumber
        purchaseOrderNumber
        transactionOrigin
        dynamicMerchantName
        frequency
        paymentMethod
        clientLocale
        paymentTitle
        paymentDescription
        orderId
        storeId
        runCount
        state
        comments
        isDeleted
        createdAt
        tranTime
        isRefund
        refundTotal
        cardHolderName
        billing
        address
        istds
        refund {
          nextToken
        }
        refundCurrency
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRefund = /* GraphQL */ `
  query GetRefund($id: ID!) {
    getRefund(id: $id) {
      id
      paymentID
      payment {
        id
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        requestID
        requestType
        PaymentType
        taTotal
        taCurrency
        CardNumber
        SecurityCode
        ExpiryDate
        currencyConversion
        clientRequestId
        apiTraceId
        ipgTransactionId
        transactionType
        transactionTime
        approveTotal
        approveCurrency
        transactionStatus
        schemeTransactionId
        responseCode
        responseMessage
        authorizationCode
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        startDate
        numberOfPayments
        maximumFailures
        invoiceNumber
        purchaseOrderNumber
        transactionOrigin
        dynamicMerchantName
        frequency
        paymentMethod
        clientLocale
        paymentTitle
        paymentDescription
        orderId
        storeId
        runCount
        state
        comments
        isDeleted
        createdAt
        tranTime
        isRefund
        refundTotal
        cardHolderName
        billing
        address
        istds
        refund {
          nextToken
        }
        refundCurrency
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        updatedAt
      }
      merchantID
      merchant {
        id
        customApiKey
        userName
        owner
        UserType
        firstName
        lastName
        profilePhotoActive
        fullName
        email
        phone
        gender
        dobDay
        dobMonth
        dobYear
        countryID
        stateID
        Address
        homestateCountryID
        homestateID
        homestateCity
        homeAddress
        emailNotification
        pushNotification
        hasAcceptedCockie
        isAccountVerified
        isActive
        isTFAActive
        isVerified
        isDenied
        isAdmin
        signUpDate
        createdAt
        updatedAt
      }
      paymentByID
      paymentBy {
        id
        customApiKey
        userName
        owner
        UserType
        firstName
        lastName
        profilePhotoActive
        fullName
        email
        phone
        gender
        dobDay
        dobMonth
        dobYear
        countryID
        stateID
        Address
        homestateCountryID
        homestateID
        homestateCity
        homeAddress
        emailNotification
        pushNotification
        hasAcceptedCockie
        isAccountVerified
        isActive
        isTFAActive
        isVerified
        isDenied
        isAdmin
        signUpDate
        createdAt
        updatedAt
      }
      paymentByEmail
      paymentByphone
      refundTitle
      refundDescription
      requestID
      orderID
      requestType
      refundTotal
      refundCurrency
      refundRequset
      refundResponse
      createdAt
      tranTime
      updatedAt
    }
  }
`;
export const listRefunds = /* GraphQL */ `
  query ListRefunds(
    $filter: ModelRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paymentID
        payment {
          id
          merchantID
          requestID
          requestType
          PaymentType
          taTotal
          taCurrency
          CardNumber
          SecurityCode
          ExpiryDate
          currencyConversion
          clientRequestId
          apiTraceId
          ipgTransactionId
          transactionType
          transactionTime
          approveTotal
          approveCurrency
          transactionStatus
          schemeTransactionId
          responseCode
          responseMessage
          authorizationCode
          statusCode
          statusDetail
          requestDetail
          responseDetail
          requestDate
          startDate
          numberOfPayments
          maximumFailures
          invoiceNumber
          purchaseOrderNumber
          transactionOrigin
          dynamicMerchantName
          frequency
          paymentMethod
          clientLocale
          paymentTitle
          paymentDescription
          orderId
          storeId
          runCount
          state
          comments
          isDeleted
          createdAt
          tranTime
          isRefund
          refundTotal
          cardHolderName
          billing
          address
          istds
          refundCurrency
          paymentByID
          paymentByEmail
          paymentByphone
          updatedAt
        }
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        refundTitle
        refundDescription
        requestID
        orderID
        requestType
        refundTotal
        refundCurrency
        refundRequset
        refundResponse
        createdAt
        tranTime
        updatedAt
      }
      nextToken
    }
  }
`;
export const byMerchantIDPayment = /* GraphQL */ `
  query ByMerchantIDPayment(
    $merchantID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byMerchantIDPayment(
      merchantID: $merchantID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        requestID
        requestType
        PaymentType
        taTotal
        taCurrency
        CardNumber
        SecurityCode
        ExpiryDate
        currencyConversion
        clientRequestId
        apiTraceId
        ipgTransactionId
        transactionType
        transactionTime
        approveTotal
        approveCurrency
        transactionStatus
        schemeTransactionId
        responseCode
        responseMessage
        authorizationCode
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        startDate
        numberOfPayments
        maximumFailures
        invoiceNumber
        purchaseOrderNumber
        transactionOrigin
        dynamicMerchantName
        frequency
        paymentMethod
        clientLocale
        paymentTitle
        paymentDescription
        orderId
        storeId
        runCount
        state
        comments
        isDeleted
        createdAt
        tranTime
        isRefund
        refundTotal
        cardHolderName
        billing
        address
        istds
        refund {
          nextToken
        }
        refundCurrency
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPaymentByIDPayment = /* GraphQL */ `
  query ByPaymentByIDPayment(
    $paymentByID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPaymentByIDPayment(
      paymentByID: $paymentByID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        requestID
        requestType
        PaymentType
        taTotal
        taCurrency
        CardNumber
        SecurityCode
        ExpiryDate
        currencyConversion
        clientRequestId
        apiTraceId
        ipgTransactionId
        transactionType
        transactionTime
        approveTotal
        approveCurrency
        transactionStatus
        schemeTransactionId
        responseCode
        responseMessage
        authorizationCode
        statusCode
        statusDetail
        requestDetail
        responseDetail
        requestDate
        startDate
        numberOfPayments
        maximumFailures
        invoiceNumber
        purchaseOrderNumber
        transactionOrigin
        dynamicMerchantName
        frequency
        paymentMethod
        clientLocale
        paymentTitle
        paymentDescription
        orderId
        storeId
        runCount
        state
        comments
        isDeleted
        createdAt
        tranTime
        isRefund
        refundTotal
        cardHolderName
        billing
        address
        istds
        refund {
          nextToken
        }
        refundCurrency
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPaymentIDRefund = /* GraphQL */ `
  query ByPaymentIDRefund(
    $paymentID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPaymentIDRefund(
      paymentID: $paymentID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentID
        payment {
          id
          merchantID
          requestID
          requestType
          PaymentType
          taTotal
          taCurrency
          CardNumber
          SecurityCode
          ExpiryDate
          currencyConversion
          clientRequestId
          apiTraceId
          ipgTransactionId
          transactionType
          transactionTime
          approveTotal
          approveCurrency
          transactionStatus
          schemeTransactionId
          responseCode
          responseMessage
          authorizationCode
          statusCode
          statusDetail
          requestDetail
          responseDetail
          requestDate
          startDate
          numberOfPayments
          maximumFailures
          invoiceNumber
          purchaseOrderNumber
          transactionOrigin
          dynamicMerchantName
          frequency
          paymentMethod
          clientLocale
          paymentTitle
          paymentDescription
          orderId
          storeId
          runCount
          state
          comments
          isDeleted
          createdAt
          tranTime
          isRefund
          refundTotal
          cardHolderName
          billing
          address
          istds
          refundCurrency
          paymentByID
          paymentByEmail
          paymentByphone
          updatedAt
        }
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        refundTitle
        refundDescription
        requestID
        orderID
        requestType
        refundTotal
        refundCurrency
        refundRequset
        refundResponse
        createdAt
        tranTime
        updatedAt
      }
      nextToken
    }
  }
`;
export const byMerchantIDRefund = /* GraphQL */ `
  query ByMerchantIDRefund(
    $merchantID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byMerchantIDRefund(
      merchantID: $merchantID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentID
        payment {
          id
          merchantID
          requestID
          requestType
          PaymentType
          taTotal
          taCurrency
          CardNumber
          SecurityCode
          ExpiryDate
          currencyConversion
          clientRequestId
          apiTraceId
          ipgTransactionId
          transactionType
          transactionTime
          approveTotal
          approveCurrency
          transactionStatus
          schemeTransactionId
          responseCode
          responseMessage
          authorizationCode
          statusCode
          statusDetail
          requestDetail
          responseDetail
          requestDate
          startDate
          numberOfPayments
          maximumFailures
          invoiceNumber
          purchaseOrderNumber
          transactionOrigin
          dynamicMerchantName
          frequency
          paymentMethod
          clientLocale
          paymentTitle
          paymentDescription
          orderId
          storeId
          runCount
          state
          comments
          isDeleted
          createdAt
          tranTime
          isRefund
          refundTotal
          cardHolderName
          billing
          address
          istds
          refundCurrency
          paymentByID
          paymentByEmail
          paymentByphone
          updatedAt
        }
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        refundTitle
        refundDescription
        requestID
        orderID
        requestType
        refundTotal
        refundCurrency
        refundRequset
        refundResponse
        createdAt
        tranTime
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPaymentByIDRefund = /* GraphQL */ `
  query ByPaymentByIDRefund(
    $paymentByID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPaymentByIDRefund(
      paymentByID: $paymentByID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentID
        payment {
          id
          merchantID
          requestID
          requestType
          PaymentType
          taTotal
          taCurrency
          CardNumber
          SecurityCode
          ExpiryDate
          currencyConversion
          clientRequestId
          apiTraceId
          ipgTransactionId
          transactionType
          transactionTime
          approveTotal
          approveCurrency
          transactionStatus
          schemeTransactionId
          responseCode
          responseMessage
          authorizationCode
          statusCode
          statusDetail
          requestDetail
          responseDetail
          requestDate
          startDate
          numberOfPayments
          maximumFailures
          invoiceNumber
          purchaseOrderNumber
          transactionOrigin
          dynamicMerchantName
          frequency
          paymentMethod
          clientLocale
          paymentTitle
          paymentDescription
          orderId
          storeId
          runCount
          state
          comments
          isDeleted
          createdAt
          tranTime
          isRefund
          refundTotal
          cardHolderName
          billing
          address
          istds
          refundCurrency
          paymentByID
          paymentByEmail
          paymentByphone
          updatedAt
        }
        merchantID
        merchant {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByID
        paymentBy {
          id
          customApiKey
          userName
          owner
          UserType
          firstName
          lastName
          profilePhotoActive
          fullName
          email
          phone
          gender
          dobDay
          dobMonth
          dobYear
          countryID
          stateID
          Address
          homestateCountryID
          homestateID
          homestateCity
          homeAddress
          emailNotification
          pushNotification
          hasAcceptedCockie
          isAccountVerified
          isActive
          isTFAActive
          isVerified
          isDenied
          isAdmin
          signUpDate
          createdAt
          updatedAt
        }
        paymentByEmail
        paymentByphone
        refundTitle
        refundDescription
        requestID
        orderID
        requestType
        refundTotal
        refundCurrency
        refundRequset
        refundResponse
        createdAt
        tranTime
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      customApiKey
      userName
      owner
      UserType
      firstName
      lastName
      profilePhotoActive
      fullName
      email
      phone
      gender
      dobDay
      dobMonth
      dobYear
      countryID
      stateID
      Address
      homestateCountryID
      homestateID
      homestateCity
      homeAddress
      emailNotification
      pushNotification
      hasAcceptedCockie
      isAccountVerified
      isActive
      isTFAActive
      isVerified
      isDenied
      isAdmin
      signUpDate
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customApiKey
        userName
        owner
        UserType
        firstName
        lastName
        profilePhotoActive
        fullName
        email
        phone
        gender
        dobDay
        dobMonth
        dobYear
        countryID
        stateID
        Address
        homestateCountryID
        homestateID
        homestateCity
        homeAddress
        emailNotification
        pushNotification
        hasAcceptedCockie
        isAccountVerified
        isActive
        isTFAActive
        isVerified
        isDenied
        isAdmin
        signUpDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        customApiKey
        userName
        owner
        UserType
        firstName
        lastName
        profilePhotoActive
        fullName
        email
        phone
        gender
        dobDay
        dobMonth
        dobYear
        countryID
        stateID
        Address
        homestateCountryID
        homestateID
        homestateCity
        homeAddress
        emailNotification
        pushNotification
        hasAcceptedCockie
        isAccountVerified
        isActive
        isTFAActive
        isVerified
        isDenied
        isAdmin
        signUpDate
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getOAuthApp = /* GraphQL */ `
  query GetOAuthApp($id: ID!) {
    getOAuthApp(id: $id) {
      id
      merchantID
      appName
      appDescription
      appID
      SecretKey
      scope
      redirectURL
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listOAuthApps = /* GraphQL */ `
  query ListOAuthApps(
    $filter: ModelOAuthAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOAuthApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchantID
        appName
        appDescription
        appID
        SecretKey
        scope
        redirectURL
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchOAuthApps = /* GraphQL */ `
  query SearchOAuthApps(
    $filter: SearchableOAuthAppFilterInput
    $sort: [SearchableOAuthAppSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOAuthAppAggregationInput]
  ) {
    searchOAuthApps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        merchantID
        appName
        appDescription
        appID
        SecretKey
        scope
        redirectURL
        isActive
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
