import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
// import { useSelector } from 'react-redux';
// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// project import
// import useAuth from './../../hooks/useAuth';
// import ServerModal from 'sections/components-overview/modal/ServerModal';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const guardValue = useAuth();
  // const selector = useSelector((state) => state.auth);
  // console.log(selector);
  // const {isLoggedIn} = useSelector((state) => state.auth)
  // const authData = useAuth();
  console.log(guardValue);
  // const navigate = useNavigate();
  

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     debugger;
  //     // navigate('/login', { replace: true });
  //   }
  // }, [isLoggedIn, navigate]);
    return children;
  
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
