import {useEffect, useState} from "react";
import { Auth} from "aws-amplify";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import LoadingAnimation from "../../components/LoadingAnimation";

const ConfirmationModal = (props: {  data : any}) => {
  console.log("helo::",props.data)
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [user, setUser] = useState({
    username: undefined
  })
  const [loading, setloading] = useState(false)

  useEffect(()=> {
    Auth.currentUserInfo().then(r=> {
      setUser(r)
    })
  },[])

  console.log('user',user)

  const handleOk = () => {
    let submitData = {
      body: {
        transactionId: props.data.ipgTransactionId,
        merchantID:user.username,
        id: props.data.clientRequestId
      }
    }
    console.log('iuagviasuib',submitData)

    const postInfo = ()=> {
    //   API.put(
    //       "paymentsgateway",
    //       `/Payments/${props.data.ipgTransactionId}`,
    //       submitData
    //   ).then(r => {
    //     console.log('resp',r)
    //     let cReq = r.authenticationResponse.params.cReq;
    //     let url = r.authenticationResponse.params.acsURL;
    //     r.transactionStatus !== 'APPROVED' && (
    //         postData(url, {
    //           creq: cReq
    //         })
    //     )
    //     setloading(false)
    //   })
    }

    setloading(true)
    setTimeout(postInfo, 10000);


    //setIsModalVisible(false);
  };


  function handleCancel() {
    setIsModalVisible(false);
  }

  return (
      <Dialog open={isModalVisible}
              fullWidth
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        {loading ? (<LoadingAnimation/>) : (
            <>
              <DialogTitle id="alert-dialog-title">
                {"Confirm Payment"}
              </DialogTitle>
              <DialogContent>
                <Typography variant={"h5"}>
                  Amount 1200 USD will be deducted from your payment Card
                </Typography>
                <Typography variant={"h6"} sx={{mt: 2}}>
                  Do you want to confirm payment?
                </Typography>

              </DialogContent>
              <DialogActions>
                <Button  autoFocus onClick={handleCancel}>
                  Disagree
                </Button>
                <Button  autoFocus onClick={handleOk}>
                  Agree
                </Button>
              </DialogActions>
            </>
        )}


      </Dialog>
  )
}

export default ConfirmationModal