import * as React from 'react';
import Box from '@mui/material/Box';
import {Fragment, useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {alpha} from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import {visuallyHidden} from '@mui/utils';
import LoadingAnimation from "../../components/LoadingAnimation";
import Collapse from "@mui/material/Collapse";
import { Divider} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


interface Data {
    dropdown: string;
    firstName: string;
    lastName: string;
    fullName: string;
    userName: string;
    email: string;
    isActive: string;
    isVerified: string;
    id: string;
    userType: string;
    isAdmin: string
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    // eslint-disable-next-line no-unused-vars
    a: { [key in Key]: string },
    // eslint-disable-next-line no-unused-vars
    b: { [key in Key]: string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// eslint-disable-next-line no-unused-vars
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'dropdown',
        numeric: false,
        disablePadding: true,
        label: '',
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: true,
        label: 'Full Name',
    },
    {
        id: 'userType',
        numeric: false,
        disablePadding: false,
        label: 'User Type',
    },
    // {
    //     id: 'fullName',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Full Name',
    // },
    {
        id: 'userName',
        numeric: false,
        disablePadding: false,
        label: 'User Name',
    },
    {
        id: 'isAdmin',
        numeric: false,
        disablePadding: false,
        label: 'Is Admin',
    },
    {
        id: 'isActive',
        numeric: false,
        disablePadding: false,
        label: 'Is Active',
    },
    {
        id: 'isVerified',
        numeric: false,
        disablePadding: false,
        label: 'IsVerified',
    },
    // {
    //     id: 'id',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Id',
    // }
];

interface EnhancedTableProps {
    numSelected: number;
    // eslint-disable-next-line no-unused-vars
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    // eslint-disable-next-line no-unused-vars
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const {numSelected} = props;

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{flex: '1 1 100%'}}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{flex: '1 1 100%'}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};


const UserTable = (props: { data: any | [] }) => {

    console.log('props ', props)

    const [paymentData,] = useState(props.data)
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [exist, setExist] = useState(false)
   
    const [sel, setSel] = useState(null)
    const [open, setOpen] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const handleDown = (id: any, index: any) => {
        setSel(id)
        setOpen(!open)
    }


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = paymentData.map((n: { transactionId: any; }) => n.transactionId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paymentData.length) : 0;


    useEffect(() => {
        paymentData.length === 0 ? setExist(false) : setExist(true)
    }, [paymentData])

    

    return (
        <Box>
            {
                exist ? (
                    <>
                        <Box sx={{width: '100%'}}>
                            <Paper sx={{width: '100%', mb: 2}}>
                                <EnhancedTableToolbar numSelected={selected.length}/>
                                <TableContainer>
                                    <Table
                                        sx={{minWidth: 750}}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={paymentData.length}
                                        />
                                        <TableBody sx={{cursor: "pointer"}}>
                                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                                            {stableSort(paymentData, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                  
                                                    return (
                                                        <Fragment>
                                                            <TableRow
                                                                sx={{'& > *': {borderBottom: 'unset'}}}
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.id}>
                                                                <TableCell>
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            handleDown(row.id, index)
                                                                        }}
                                                                    >
                                                                        {open && row.id === sel ?
                                                                            <KeyboardArrowUpIcon/> :
                                                                            <KeyboardArrowDownIcon/>}
                                                                    </IconButton>

                                                                </TableCell>
                                                                <TableCell align="center">{row.fullName}</TableCell>
                                                                <TableCell align="center">{row.userType}</TableCell>
                                                                <TableCell align="center">{row.userName}</TableCell>
                                                                <TableCell align="center">{row.isAdmin}</TableCell>
                                                                <TableCell align="center">{row.isActive}</TableCell>
                                                                <TableCell align="center">{row.isVerified}</TableCell>
                                                            </TableRow>
                                                            {row.id === sel && (
                                                                <TableRow
                                                                    tabIndex={-1}
                                                                    key={row.id}>
                                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}}
                                                                               colSpan={12}>
                                                                        <Collapse in={open} timeout="auto"
                                                                                  unmountOnExit>
                                                                            <Box>
                                                                                <Typography variant="h6" gutterBottom
                                                                                            component="div">
                                                                                    More Info
                                                                                </Typography>
                                                                                <Table size="small"
                                                                                       aria-label="purchases">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align="center">
                                                                                                First Name
                                                                                            </TableCell>
                                                                                            <TableCell align="center">
                                                                                                Last Name
                                                                                            </TableCell>
                                                                                            <TableCell align="center">
                                                                                                User Name
                                                                                            </TableCell>
                                                                                            <TableCell align="center">
                                                                                                ID
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        <TableRow>
                                                                                            <TableCell component="th"
                                                                                                       align="center"
                                                                                                       scope="row">
                                                                                                {row.firstName}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                align="center"> {row.lastName}</TableCell>
                                                                                            <TableCell
                                                                                                align="center"> {row.email}</TableCell>
                                                                                            <TableCell
                                                                                                align="center">
                                                                                                {row.id}
                                                                                            </TableCell>

                                                                                        </TableRow>
                                                                                    </TableBody>
                                                                                </Table>
                                                                                <Divider sx={{m: 2}}/>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Fragment>

                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: (53) * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={paymentData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Box>
                    </>
                ) : (
                    <LoadingAnimation/>
                )
            }

        </Box>
    )
}

export default UserTable