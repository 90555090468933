import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import PayInfo from "../pages/paymentInfo";
import SinglePayInfo from "../pages/paymentInfo/SinglePayInfo";
import RecurringInfo from "../pages/recurringInfo";
import SingleRecurring from "../pages/recurringInfo/SingleRecurring";
import DemoPayment from "../pages/demo payment user/demoPayment";
import CompletePayment from "../pages/demo payment user/CompletePayment";
import DemoSchedule from "../pages/demoSchedule/DemoSchedule";
import RefundAll from "../pages/refundInfo";
import AllUser from "../pages/user/allUser";
import ApproveUser from "../pages/user/approveUser";
import PendingUser from "../pages/user/pendingUser"
import AdminInfo from "../pages/admin/adminInfo";
import UserDetails from "../pages/user/userDetails";
import EnhancedTable from "../utils/TableCheck";
import OtherWebsite from "../pages/demo payment merchant/demoPayment";
import AuthGuard from '../utils/route-guard/AuthGuard';


// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));


// @ts-ignore
const MainRoutes = {
	path: '/',
	element: (
		<AuthGuard>
			<MainLayout />
		</AuthGuard>),
	children: [
		{
			path: '/',
			element: (
				<AuthGuard>
					<DashboardDefault />
				</AuthGuard>)
		},
		{
			path: 'dashboard',
			children: [
				{
					path: '',
					element: (
						<AuthGuard>
							<DashboardDefault />
						</AuthGuard>)
				}
			]
		},
		{
			path: 'payment-info',
			element: (
				<AuthGuard>
					<PayInfo />
				</AuthGuard>),
		},

		{
			path: 'payment-info/:id',
			element: (
				<AuthGuard>
					<SinglePayInfo />
				</AuthGuard>)
		},
		{
			path: 'recurring-payment-info',
			element: (
				<AuthGuard>
					<RecurringInfo />
				</AuthGuard>)
		},
		{
			path: 'recurring-info/:id',
			element: (
				<AuthGuard>
					<SingleRecurring />
				</AuthGuard>)
		},
		{
			path: 'demo-payment',
			element: (
				<AuthGuard>
					<DemoPayment />
				</AuthGuard>)
		},
		{
			path: 'other-website-payment',
			element: (
				<AuthGuard>
					<OtherWebsite />
				</AuthGuard>)
		},
		{
			path: 'demo-schedule-payment',
			element: (
				<AuthGuard>
					<DemoSchedule />
				</AuthGuard>)
		},
		{
			path: 'complete-payment/:id',
			element: (
				<AuthGuard>
					<CompletePayment />
				</AuthGuard>)
		},
		{
			path: 'refund-info',
			element: (
				<AuthGuard>
					<RefundAll />
				</AuthGuard>)
		},
		{
			path: 'admin',
			element: (
				<AuthGuard>
					<AdminInfo />
				</AuthGuard>)
		},
		{
			path: 'all-user',
			element: (
				<AuthGuard>
					<AllUser />
				</AuthGuard>)
		},
		{
			path: 'approve-user',
			element: (
				<AuthGuard>
					<ApproveUser />
				</AuthGuard>)
		},
		{
			path: 'pending_user',
			element: (
				<AuthGuard>
					<PendingUser />
				</AuthGuard>)
		},
		{
			path: 'user/:id',
			element: (
				<AuthGuard>
					<UserDetails />
				</AuthGuard>)
		},
		{
			path: 'table',
			element: (
				<AuthGuard>
					<EnhancedTable />
				</AuthGuard>)
		}
	]
};

export default MainRoutes;
