import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField, Typography
} from "@mui/material";
import {useEffect, useState} from "react";

import { Auth} from "aws-amplify";
import LoadingAnimation from "../../components/LoadingAnimation";
import Notification from "../notification/notification";
import animationData from "../../assets/23210-wallet-money-added.json";
import Lottie from "react-lottie";

const Refund = (props: { data: any }) => {
    const [open, setOpen] = useState(true);
    const [show, setShow] = useState(false)
	 // eslint-disable-next-line no-unused-vars
    const [message, setMessage] = useState('')
    const [notification, setNotification] = useState(false)
	 // eslint-disable-next-line no-unused-vars
    const [type, setType] = useState('success')
	 // eslint-disable-next-line no-unused-vars
    const [click, setClick] = useState(true)
    const [user, setUser] = useState({
        username: null
    })
    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])

    console.log('datass', props.data)
    const handleClose = () => {
        setOpen(false);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const handleSubmit = async () => {

        setShow(true)
        let submitData = {
            "body": {
                "total": parseInt(props.data.taTotal),
                "currency": props.data.taCurrency,
                "merchantID": props.data.merchantID,
                "userId" : user.username,
                "id": props.data.id
            }
        }
        console.log(submitData)
        // await API.post(
        //     "paymentsgateway",
        //     `/Order/${props.data.orderId}`,
        //     submitData
        // ).then(r => {
        //     console.log(r)
        //     setClick(false)
        //     setType('success')
        //     setNotification(true)
        //     setMessage('Refund successfully')
        //     // setOpen(false);
        //     setShow(false)
        // }).catch(err => {
        //     console.log(err)
        //     setType('error')
        //     setMessage(`Refund failed`)
        //     setNotification(true)
        //     setShow(false)
        // })

    }

    return (
        <Dialog open={open}
                fullWidth
                maxWidth={'xs'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {click ? "Do you want to refund this payment?" : "Refund Status"}
            </DialogTitle>
            {Notification(message, notification, setNotification, type)}
            <DialogContent>
                {
                    click &&
                    <Grid container spacing={2} sx={{mt: 2}}>
                        <Grid item sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Enter Amount"
                                variant="outlined"
                                defaultValue={parseInt(props.data.taTotal)}
                            />
                        </Grid>
                        <Grid item sm={6} sx={{mt: 1}}>
                            <Typography>
                                {props.data.taCurrency}
                            </Typography>
                        </Grid>
                    </Grid>
                }

                {!click &&
                    <Box>
                        <Lottie
                            options={defaultOptions}
                            height={200}
                            width={200}
                        />
                        <Typography textAlign={'center'}>
                            Refunded
                        </Typography>
                    </Box>
                }

            </DialogContent>
            {
                show && (<LoadingAnimation/>)
            }

            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                {click && <Button autoFocus onClick={handleSubmit}>
                    Agree
                </Button>}
            </DialogActions>


        </Dialog>
    )
}

export default Refund