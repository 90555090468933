import * as React from 'react';
import Box from '@mui/material/Box';
import {Fragment, useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {visuallyHidden} from '@mui/utils';
import LoadingAnimation from "../../components/LoadingAnimation";
import {Button, Divider, Stack, TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {Search} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {SubmitHandler, useForm} from "react-hook-form";


interface Data {
    dropdown: any;
    date: string;
    startDate: string;
    numberOfPayments: string;
    frequency: string
    taTotal: string;
    merchantID: string;
    orderId: string;
    state: string;
    id: string;
    merchantName: string;
    transactionOrigin:string;
    apiTraceId:string;
    requestType:string;
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    // eslint-disable-next-line no-unused-vars
    a: { [key in Key]:  string },
    // eslint-disable-next-line no-unused-vars
    b: { [key in Key]:  string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// eslint-disable-next-line no-unused-vars
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'dropdown',
        numeric: false,
        disablePadding: true,
        label: '',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        label: 'Transaction Date',
    },
    {
        id: 'merchantName',
        numeric: false,
        disablePadding: false,
        label: 'Merchant Name',
    },
    {
        id: 'startDate',
        numeric: false,
        disablePadding: false,
        label: 'Start Date',
    },
    {
        id: 'numberOfPayments',
        numeric: false,
        disablePadding: false,
        label: 'Number of Payments',
    },    {
        id: 'frequency',
        numeric: false,
        disablePadding: false,
        label: 'Frequency',
    },
    {
        id: 'taTotal',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Total',
    },
    // {
    //     id: 'orderId',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Order ID',
    // },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'Transaction State',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    // eslint-disable-next-line no-unused-vars
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    // eslint-disable-next-line no-unused-vars
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort} =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const AllRecurring = (props: { data: any | [] }) => {
    const [paymentData, setPaymentData] = useState(props.data)
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('orderId');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [exist, setExist] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [sel, setSel] = useState(null)
    const [key, setKey] = useState(false)

    useEffect(() => {
        !key && setPaymentData(props.data)
    }, [props.data, key])

    useEffect(() => {
        console.log('check::', rowsPerPage, page)
        // props.updateFrom(rowsPerPage, page)
    }, [rowsPerPage, page])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = paymentData.map((n: { transactionId: any; }) => n.transactionId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paymentData.length) : 0;


    useEffect(() => {
        paymentData.length === 0 ? setExist(false) : setExist(true)
    }, [paymentData.length, props.data])


    // eslint-disable-next-line no-unused-vars
    const handleDown = (id: any, index: any) => {
        setSel(id)
        setOpen(!open)
    }
    type Inputs = {
        searchParam: string,
    };
    const {register, handleSubmit, watch, formState: {errors}, getValues} = useForm<Inputs>();

    useEffect(()=> {
        let s = watch("searchParam")
        // console.log('jj',s.length)
        s!== '' ? setKey(true) : setKey(false)
    },[getValues("searchParam")])

    // console.log(watch("searchParam"))

    const onSubmit: SubmitHandler<Inputs> = data => {
        let searchPayments : [] = paymentData.filter((d : any) => {
            if (d.merchantID.toLowerCase().match(data.searchParam.toLowerCase())){
                return d
            }
            else if (d.merchantName.toLowerCase().match(data.searchParam.toLowerCase())){
                return d
            }
            else if (d.orderId.toLowerCase().match(data.searchParam.toLowerCase())){
                return d
            }
        })

        console.log('searchPayments',searchPayments)
        setPaymentData(searchPayments)
    };

    interface EnhancedTableToolbarProps {
        numSelected: number;
    }


    // eslint-disable-next-line no-unused-vars
    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        return (
            <Toolbar
                sx={{
                    pl: {sm: 2},
                    pr: {xs: 1, sm: 1},
                }}
            >

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <TextField
                            {...register('searchParam')}
                            id="input-with-icon-textfield"
                            label="Search"
                            autoFocus
                            error={Boolean(errors.searchParam)}
                            helperText={errors.searchParam?.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                        <Button type={'submit'} variant="outlined">
                            Search
                        </Button>
                    </Stack>
                </form>
            </Toolbar>
        );
    };


    return (
        <Box>
            {
                exist ? (
                    <>
                        <Box sx={{width: '100%'}}>
                            <Paper sx={{width: '100%', mb: 2}}>
                                <EnhancedTableToolbar numSelected={selected.length}/>
                                <TableContainer>
                                    <Table
                                        sx={{minWidth: 750}}
                                        aria-labelledby="tableTitle"
                                        size={ 'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={paymentData.length}
                                        />
                                        <TableBody sx={{cursor: "pointer"}}>
                                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                                            {stableSort(paymentData, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    // const isItemSelected = isSelected(row.id);

                                                    return (

                                                        <Fragment key={index}>
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                // aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={row.orderId}
                                                                // selected={isItemSelected}
                                                            >
                                                                <TableCell>
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            handleDown(row.id, index)
                                                                        }}
                                                                    >
                                                                        {open && row.id === sel ? <KeyboardArrowUpIcon/> :
                                                                            <KeyboardArrowDownIcon/>}
                                                                    </IconButton>
                                                                </TableCell>
                                                                <TableCell align="center">{row.date}</TableCell>
                                                                <TableCell align="center">{row.merchantName}</TableCell>
                                                                <TableCell align="center">{row.startDate}</TableCell>
                                                                <TableCell align="center">{row.numberOfPayments}</TableCell>
                                                                <TableCell align="center">{row.frequency}</TableCell>
                                                                <TableCell align="center">{row.taTotal}</TableCell>
                                                                {/*<TableCell align="center">{row.orderId}</TableCell>*/}
                                                                <TableCell align="center">{row.state ? row.state : "FAILED"}</TableCell>
                                                            </TableRow>
                                                            {row.id === sel && (
                                                                <TableRow
                                                                    tabIndex={-1}
                                                                    key={row.orderId}>
                                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}}
                                                                               colSpan={12}>
                                                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                                                            <Box>
                                                                                <Typography variant="h6" gutterBottom
                                                                                            component="div">
                                                                                    More Info
                                                                                </Typography>
                                                                                <Table size="small" aria-label="purchases">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align="center">ID</TableCell>
                                                                                            <TableCell align="center">Merchant
                                                                                                ID</TableCell>
                                                                                            <TableCell align="center">Order
                                                                                                ID</TableCell>
                                                                                            {/*<TableCell align="center">Transaction*/}
                                                                                            {/*    Origin</TableCell>*/}
                                                                                            <TableCell align="center">Request
                                                                                                Type</TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        <TableRow>
                                                                                            <TableCell component="th"
                                                                                                       align="center"
                                                                                                       scope="row">
                                                                                                {row.id}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                align="center">{row.merchantID}</TableCell>
                                                                                            <TableCell
                                                                                                align="center">{row.orderId ? row.orderId : "Scedule failed"}</TableCell>
                                                                                            {/*<TableCell align="center">*/}
                                                                                            {/*    {row.transactionOrigin}*/}
                                                                                            {/*</TableCell>*/}
                                                                                        {/*    <TableCell*/}
                                                                                        {/*    align="center">*/}
                                                                                        {/*    {row.apiTraceId}*/}
                                                                                        {/*</TableCell> */}
                                                                                            <TableCell
                                                                                            align="center">
                                                                                            {row.requestType}
                                                                                        </TableCell>
                                                                                        </TableRow>
                                                                                    </TableBody>
                                                                                </Table>
                                                                                <Divider sx={{m: 2}}/>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Fragment>

                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: ( 53) * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={paymentData.length || paymentData[0].total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Box>
                    </>
                ) : (
                    <LoadingAnimation/>
                )
            }

        </Box>
    )
}

export default AllRecurring