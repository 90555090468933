import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {listUsers} from "../../graphql/queries";
import LoadingAnimation from "../../components/LoadingAnimation";
import PendingUserTable from "./PendingUserTable";


const PendingUser = () => {

    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState({
        username: undefined
    })

    let getData = async () => {
        const response: any = await API.graphql({
            query: listUsers,
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        const data = response.data.listUsers.items
        return data && data.filter((filteredData: { isVerified: boolean; isDenied: boolean; }) => !filteredData.isVerified && !filteredData.isDenied)
    }

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])


    useEffect(() => {
        getData().then(r => {
            setLoader(false)
            setData(r.map((d : any)=> {
                console.log('allRecord::',r)
                return {
                    firstName : d.firstName,
                    lastName : d.lastName,
                    fullName : d.fullName,
                    userName : d.userName,
                    email : d.email,
                    isActive : d.isActive,
                    isVerified : d.isVerified,
                    userType : d.UserType,
                    id: d.id
                }
            }))
        })
    }, [])



    return (
        <div>
            {allData.length===0 ?  ( loader ? <LoadingAnimation/> : <h3 >No data found</h3>) : (<PendingUserTable data={allData}/>)}
        </div>
    )
}

export default PendingUser