import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {listUsers} from "../../graphql/queries";
import LoadingAnimation from "../../components/LoadingAnimation";
import ApproveUserTable from "./ApproveUserTable";


const ApprovedUser = () => {

    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState({
        username: undefined
    })
    const [flag, setFlag] = useState(false)

    let changeFlag = (param: boolean) => {
        setFlag(param)
    }

    let getData = async () => {
        const response: any = await API.graphql({
            query: listUsers,
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        const data = response.data.listUsers.items
        return data && data.filter((filteredData: { isVerified: boolean; }) => filteredData.isVerified === true)
    }

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])


    let setUserData = async () => {

        getData().then(r => {

            setLoader(false)
            //console.log("res",r)
            setData(r.map((d: any) => {
                console.log("flag", flag)
                return {
                    firstName: d.firstName,
                    lastName: d.lastName,
                    fullName: d.fullName,
                    userName: d.userName,
                    email: d.email,
                    isActive: d.isActive,
                    isVerified: d.isVerified,
                    customApiKey: d.customApiKey,
                    userType : d.UserType,
                    id: d.id
                }
            }))
        })
    }

    useEffect(() => {
        setUserData()
    }, [flag])


    // @ts-ignore
    return (
        <div>
            {allData.length === 0 ? (loader ? <LoadingAnimation/> : <h3>No data found</h3>) : (
                <ApproveUserTable data={allData} flag={flag} changeFlag={changeFlag}/>)}
        </div>
    )
}

export default ApprovedUser