import * as React from 'react';
import Box from '@mui/material/Box';
import {Fragment, useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {visuallyHidden} from '@mui/utils';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import JsonFormatter from 'react-json-formatter'

import {useNavigate} from "react-router-dom";
import {
    Button, Divider, Grid, MenuItem, Pagination,
    Stack,
    TextField
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {Search} from "@mui/icons-material";

import Collapse from "@mui/material/Collapse";
import tables from "../../utils/TableContent";
import {SubmitHandler, useForm} from "react-hook-form";
import {rowOption} from "../../assets/month";


interface Data {
    dropdown: any
    date: string;
    transactionType: string;
    transactionStatus: string;
    taTotal: string;
    approveTotal: string;
    transactionId: string;
    isRefund: boolean;
    merchantID: string;
    id: string;
    merchantName: string;
    transactionOrigin: string;
    requestType: string;
    orderId: string;
    apiTraceId: string;
    customApiKey: string;
    userName: string;
    CardNumber: string,
    month: string,
    year: string
    SecurityCode: string,
    paymentDescription: string,
    paymentTitle:string
    allInfo: string | number,
    paymentByID : string,
    userType :string,
    transactedFrom: string,
    paymentByEmail :string,
    paymentByphone :string,
    senderName:string,
    senderType:string,
    requestDetail:string
}


type Order = 'asc' | 'desc';


interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'dropdown',
        numeric: false,
        disablePadding: true,
        label: '',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    // {
    //     id: 'merchantName',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Name (User type)',
    // },
    {
        id: 'transactedFrom',
        numeric: false,
        disablePadding: false,
        label: 'Transacted from',
    },
    {
        id: 'paymentByID',
        numeric: false,
        disablePadding: false,
        label: 'Transacted To',
    },
    {
        id: 'transactionStatus',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Status',
    },
    {
        id: 'taTotal',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Total',
    },
    {
        id: 'approveTotal',
        numeric: false,
        disablePadding: false,
        label: 'Approved Total',
    },
    {
        id: 'isRefund',
        numeric: false,
        disablePadding: false,
        label: 'Refund Status',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    // eslint-disable-next-line no-unused-vars
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    // eslint-disable-next-line no-unused-vars
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const PaymentInfo = (props: { data: any | [], limit: number, from: number, updateFrom: any, searchParam?: any }) => {

    const jsonStyle = {
        propertyStyle: { color: '#e3242b' },
        stringStyle: { color: '#01BF71' },
        numberStyle: { color: 'orange' }
    }

    const [show, setShow] = useState(false);
    const [showReq, setReqShow] = useState(false);

    const handleClickOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };
    const handleClickOpenReq = () => {
        setReqShow(true);
    };

    const handleCloseReq = () => {
        setReqShow(false);
    };


    const [paymentData, setPaymentData] = useState(props.data)
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('transactionId');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [exist, setExist] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [sel, setSel] = useState(null)
    const [key, setKey] = useState(false)
    const [count, setCount] = useState(1)
    const router = useNavigate()
    console.log('passed data', paymentData)

    useEffect(() => {
        setCount(Math.ceil(props.data[0]?.total  / rowsPerPage || 0))

    }, [props.data, rowsPerPage])
    useEffect(() => {
        !key && setPaymentData(props.data)
    }, [props.data, key])

    useEffect(() => {
        console.log('check::', rowsPerPage, page)
        props.updateFrom(rowsPerPage, page)
    }, [rowsPerPage, page])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = paymentData.map((n: { transactionId: any; }) => n.transactionId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage-1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paymentData.length) : 0;


    useEffect(() => {
        paymentData.length === 0 ? setExist(false) : setExist(true)
    }, [paymentData.length, props.data])


    // eslint-disable-next-line no-unused-vars
    const handleDown = (id: any, index: any) => {
        setSel(id)
        setOpen(!open)
    }
    type Inputs = {
        searchParam: string,
    };
    const {register, handleSubmit, watch, formState: {errors}, getValues} = useForm<Inputs>();

    useEffect(() => {
        let s = watch("searchParam")
        console.log('jj', s.length)
        s.length !== 0 ? setKey(true) : setKey(false)
    }, [getValues("searchParam")])

    // console.log(watch("searchParam"))

    const onSubmit: SubmitHandler<Inputs> = data => {
        let searchPayments: [] = paymentData.filter((d: any) => {
            if (d.merchantID.toLowerCase().match(data.searchParam.toLowerCase())) {
                return d
            } else if (d.transactionId.toLowerCase().match(data.searchParam.toLowerCase())) {
                return d
            } else if (d.merchantName.toLowerCase().match(data.searchParam.toLowerCase())) {
                return d
            } else if (d.orderId.toLowerCase().match(data.searchParam.toLowerCase())) {
                return d
            }
        })

        console.log('searchPayments', searchPayments)
        setPaymentData(searchPayments)
    };

    interface EnhancedTableToolbarProps {
        numSelected: number;
    }

 // eslint-disable-next-line no-unused-vars
    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        return (
            <Toolbar
                sx={{
                    pl: {sm: 2},
                    pr: {xs: 1, sm: 1},
                }}
            >

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <TextField
                            {...register('searchParam')}
                            id="input-with-icon-textfield"
                            label="Search"
                            autoFocus
                            error={Boolean(errors.searchParam)}
                            helperText={errors.searchParam?.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                        <Button type={'submit'} variant="outlined">
                            Search
                        </Button>
                    </Stack>
                </form>
            </Toolbar>
        );
    };


    return (
        <Box>
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 2}}>
                    <EnhancedTableToolbar numSelected={selected.length}/>
                    <TableContainer>
                        <Table
                            sx={{minWidth: 750}}
                            aria-labelledby="tableTitle"
                            size={'medium'}>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={paymentData.length}
                            />

                            <TableBody sx={{cursor: "pointer"}}>


                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}

                                {tables.stableSort(paymentData, tables.getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <Fragment key={row.id}>
                                                {
                                                    exist && (
                                                        <>
                                                            <TableRow
                                                                sx={{'& > *': {borderBottom: 'unset'}}}
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.id}>
                                                                <TableCell>
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            handleDown(row.id, index)
                                                                        }}
                                                                    >
                                                                        {open && row.id === sel ? <KeyboardArrowUpIcon/> :
                                                                            <KeyboardArrowDownIcon/>}
                                                                    </IconButton>

                                                                </TableCell>

                                                                <TableCell align="center">
                                                                    {row.date}
                                                                </TableCell>

                                                                <TableCell align="center">{row.paymentByEmail || row.paymentByphone || `${row.senderName} (${row.senderType})`}</TableCell>
                                                                <TableCell align="center">{row.merchantName} {`(${row.userType})`}</TableCell>
                                                                {/*<TableCell*/}
                                                                {/*    align="center">{row.merchantID}</TableCell>*/}
                                                                <TableCell
                                                                    align="center">{row.transactionStatus ? row.transactionStatus : 'FAILED'}</TableCell>
                                                                <TableCell align="center">{row.taTotal}</TableCell>
                                                                <TableCell align="center">{row.approveTotal}</TableCell>

                                                                <TableCell align="center">{ row.transactionStatus === "WAITING"  ?
                                                                    <Typography>Not Refundable</Typography> :
                                                                    row.isRefund ?
                                                                        <Typography>Refunded</Typography> :
                                                                    <Typography>Refundable</Typography>}
                                                                </TableCell>
                                                            </TableRow>
                                                            {row.id === sel && (
                                                                <TableRow
                                                                    tabIndex={-1}
                                                                    key={row.transactionId}>
                                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}}
                                                                               colSpan={12}>
                                                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                                                            <Box>
                                                                                <Typography variant="h6" gutterBottom
                                                                                            component="div">
                                                                                    More Info
                                                                                </Typography>
                                                                                <Table size="small" aria-label="purchases">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align="center">Payment
                                                                                                ID</TableCell>
                                                                                            <TableCell align="center">Merchant
                                                                                                ID</TableCell>
                                                                                            <TableCell align="center">Transaction Type</TableCell>
                                                                                            <TableCell align="center">Order
                                                                                                ID</TableCell>
                                                                                            <TableCell align="center">Transaction ID</TableCell>
                                                                                            <TableCell align="center">Transaction
                                                                                                Origin</TableCell>
                                                                                            <TableCell align="center">Request
                                                                                                Type</TableCell>
                                                                                            <TableCell
                                                                                                align="center">Refund</TableCell>

                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        <TableRow>
                                                                                            <TableCell component="th"
                                                                                                       align="center"
                                                                                                       scope="row">
                                                                                                {row.id}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                align="center">{row.merchantID}</TableCell>
                                                                                            <TableCell
                                                                                                align="center">{row.transactionType}</TableCell>
                                                                                            <TableCell
                                                                                                align="center">{row.orderId ? row.orderId : "Transaction failed"}</TableCell>
                                                                                            <TableCell
                                                                                                align="center">{row.transactionId ? row.transactionId : "Transaction failed"}</TableCell>
                                                                                            <TableCell align="center">
                                                                                                {row.transactionOrigin ? row.transactionOrigin : "Transaction failed"}
                                                                                            </TableCell> <TableCell
                                                                                            align="center">
                                                                                            {row.requestType}
                                                                                        </TableCell>
                                                                                            {!row.isRefund && row.transactionStatus === "APPROVED"  ?
                                                                                                <TableCell
                                                                                                    align="center">
                                                                                                    <Button
                                                                                                        variant={"outlined"}
                                                                                                        onClick={() => router(`/payment-info/${row.id}`)}>Refund</Button>
                                                                                                </TableCell> : <TableCell>
                                                                                                    <Typography>
                                                                                                        Not Refundable
                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                            }
                                                                                        </TableRow>
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </Box>
                                                                            <Grid container sx={{mt: 1}} spacing={2} minHeight={"max-content"}>
                                                                                    <Grid item sm={4} xs={12}>
                                                                                        <Paper elevation={2} sx={{p: 2}}>
                                                                                            <Stack spacing={1}>
                                                                                                <Typography variant={'h5'}>Credit
                                                                                                    Card Info</Typography>
                                                                                                <Typography>Card Number
                                                                                                    : {row.CardNumber}</Typography>
                                                                                                <Typography>Expire Month
                                                                                                    : {row.month}</Typography>
                                                                                                <Typography>Expire Year
                                                                                                    : {row.year}</Typography>

                                                                                                <Typography>Security Code
                                                                                                    : {row.SecurityCode}</Typography>
                                                                                            </Stack>

                                                                                        </Paper>
                                                                                    </Grid>
                                                                                    <Grid item sm={ 8 } xs={12} >
                                                                                        <Paper elevation={2} sx={{p: 2}}>
                                                                                            <Button variant="outlined" onClick={handleClickOpenReq}>
                                                                                                Show Request
                                                                                            </Button>
                                                                                            <Button sx={{ml:2}} variant="outlined" onClick={handleClickOpen}>
                                                                                                All Response
                                                                                            </Button>

                                                                                            <Dialog
                                                                                                open={show}
                                                                                                onClose={handleClose}
                                                                                                fullWidth
                                                                                                aria-labelledby="alert-dialog-title"
                                                                                                aria-describedby="alert-dialog-description">
                                                                                                <DialogTitle id="alert-dialog-title">
                                                                                                    {"All Response from fiserv"}
                                                                                                </DialogTitle>
                                                                                                <DialogContent>
                                                                                                    <DialogContentText id="alert-dialog-description">
                                                                                                        <JsonFormatter json={`${row.allInfo}`}  tabWith={4} jsonStyle={jsonStyle} />
                                                                                                    </DialogContentText>
                                                                                                </DialogContent>
                                                                                                <DialogActions>
                                                                                                    <Button onClick={handleClose}>CLose</Button>
                                                                                                </DialogActions>
                                                                                            </Dialog>
                                                                                            <Dialog
                                                                                                open={showReq}
                                                                                                onClose={handleCloseReq}
                                                                                                fullWidth
                                                                                                aria-labelledby="alert-dialog-title"
                                                                                                aria-describedby="alert-dialog-description">
                                                                                                <DialogTitle id="alert-dialog-title">
                                                                                                    {"Req to fiserv"}
                                                                                                </DialogTitle>
                                                                                                <DialogContent>
                                                                                                    <DialogContentText id="alert-dialog-description">
                                                                                                        <JsonFormatter json={`${row.requestDetail}`}  tabWith={4} jsonStyle={jsonStyle} />
                                                                                                    </DialogContentText>
                                                                                                </DialogContent>
                                                                                                <DialogActions>
                                                                                                    <Button onClick={handleCloseReq}>CLose</Button>

                                                                                                </DialogActions>
                                                                                            </Dialog>

                                                                                        </Paper>

                                                                                        <Paper elevation={2} sx={{p: 2, mt:2}} >

                                                                                            <Typography variant={'h5'}>
                                                                                                Short Description : {row?.paymentTitle}
                                                                                            </Typography>
                                                                                                    <Typography >
                                                                                                        Long Description :   {row?.paymentDescription}
                                                                                                    </Typography>
                                                                                        </Paper>
                                                                                    </Grid>


                                                                            </Grid>
                                                                            <Divider sx={{m: 2}}/>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}

                                                        </>

                                                    )
                                                }

                                            </Fragment>

                                        );
                                    })}


                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {!exist && (
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Typography>No Data</Typography>
                            </Box>
                        )}
                    </TableContainer>

                    <Box sx={{display: 'flex', justifyContent: 'flex-end', p:2}}>
                        <Typography sx={{p:1}}>
                            Row per page
                        </Typography>
                        <TextField
                            size={"small"}
                            id="outlined-select-currency"
                            select
                            sx={{pr: 1}}
                            onChange={handleChangeRowsPerPage}
                            defaultValue={5}
                        >
                            {rowOption.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Pagination showFirstButton
                                    showLastButton count={count} onChange={handleChangePage} page={page} defaultPage={1}
                                    variant="outlined" shape="rounded"/>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default PaymentInfo