import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import LoadingAnimation from "../../components/LoadingAnimation";
import RefundRecord from "./RefundRecord";


const RefundAll = () => {
 // eslint-disable-next-line no-unused-vars
    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
	 // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState({
        username: undefined
    })

  

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
		setLoader(false)
    }, [])



    // console.log("allData",allData)

    return (
        <div>
            {allData.length===0 ?  ( loader ? <LoadingAnimation/> : <h3 >No data found</h3>) : (<RefundRecord data={allData}/>)}
        </div>
    )
}

export default RefundAll