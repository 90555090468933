import LoadingAnimation from "../../components/LoadingAnimation";
import {
    Box,
    Button,
    Container,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {useForm} from "react-hook-form";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {MobileDatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {currencies, month, year} from "../../assets/month";
import Notification from "../notification/notification";
import * as React from "react";
type FormValues = {
    currency: number,
    amount: number,
    number: string,
    month: string,
    year: string,
    securityCode: string,
    numberOfPayments:string,
    every:string,
    unit:string,
};


const DemoSchedule = () => {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({
        username: null
    })
    // eslint-disable-next-line no-unused-vars
    const [conformation, setConformation] = useState(false)
    const [confirmData, setConformData] = useState({})
    const [transactionID, setTransactionID] = useState(null)
    // eslint-disable-next-line no-unused-vars
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [message, setMessage] = useState('')
    const [notification, setNotification] = useState(false)
    const [type, setType] = useState('success')
    const showModal = () => {
        setIsModalVisible(true);
    };
    let [date, setDate] = (useState<Date | null>(
        new Date()
    ))

    console.log("date",moment(date).format("yyyy-MM-DD"))
    // eslint-disable-next-line no-unused-vars
    let [unit, setUnit] = useState('');

    useEffect(() => {
        if (transactionID) {
            setConformation(true)
        }

    }, [transactionID])

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])



    // eslint-disable-next-line no-unused-vars
    const {register, handleSubmit, setError, formState: {errors}, watch} = useForm<FormValues>(
        {
        defaultValues: {
            // number: "4005520000000129",
            number: "",
            // month: "10",
            month: "0",
            // year: "22",
            year: "0",
            // securityCode: "002",
            securityCode: "",
            // numberOfPayments: "10",
            numberOfPayments: "0",
            // every: "1",
            every: "0",
            unit: ""
        }
    }
    );



    const onSubmit = async (data: any) => {
        setLoading(true)
        let time = moment(date).format("yyyy-MM-DD")
        let submitData = {
            body: {
                ...data,
                merchantID: user.username,
                requestType: "PaymentMethodPaymentSchedulesRequest",
                maximumFailures: 1,
                invoiceNumber: "96126098",
                purchaseOrderNumber: "123055342",
                transactionOrigin: "ECOM",
                dynamicMerchantName: "MyWebsite",
                cardFunction: "CREDIT",
                language: "EN",
                country: "USA",
                startDate: time
            }
        }
        console.log(submitData)

        await API.post(
            "paymentsgateway",
            `/PaymentSchedules/${user.username}`,
            submitData
        ).then(async (res: { ipgTransactionId: any; }) => {

            setType('success')
            setNotification(true)
            setMessage('Payment successfully')

            setLoading(false)
            await setConformData({
                ...res
            })
            await setTransactionID(res.ipgTransactionId)
            console.log('awsdata', confirmData)
        }).catch(errors => {
            console.log('errors', errors)
            setType('error')
            setMessage(`Payment failed : ${errors.message}`)
            setNotification(true)
            setLoading(false)
            showModal()
            console.log('1:::', errors);
            // console.log(error.response.data.error.details);
            // console.log('2:::', error.response.status);
            // setError(`custom`, {
            //     type: 'custom',
            //     message: errors
            // }, {shouldFocus: true})
        });
    }


    const handleDate = (newValue: Date | null) => {
        setDate(newValue);
    };

    const handleUnit = (e: any) => {
        setUnit(e.target.value)
    };

    return (
        <Paper elevation={3} sx={{p: 2}}>
            <Container>
                {Notification(message,notification,setNotification, type)}
                <Typography variant={'h4'} textAlign={"center"}>
                    Payment
                </Typography>
                <Divider sx={{m: 2}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ml: 2}}>
                            <Paper sx={{p: 2}}>
                                <Typography>
                                    Credit Card Info
                                </Typography>
                                <Grid container spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField fullWidth label={"Price"}

                                                   {...register("amount", {
                                                       required: {
                                                           value: true, message: 'Price is required'
                                                       },
                                                       min: {
                                                           value: 0, message: 'Amount can not ber negetive'
                                                       }
                                                   })} type={"number"}
                                                   error={Boolean(errors.amount)}
                                                   helperText={errors.amount?.message}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-currency"
                                            select
                                            label="Currency"

                                            {...register("currency", {
                                                required: {
                                                    value: true, message: 'Currency is required'
                                                }
                                            })}
                                            error={Boolean(errors.currency)}
                                            helperText={errors.currency?.message}

                                        >
                                            {currencies.map((option: any) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Box sx={{mt: 2}}>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <TextField fullWidth {...register("number", {required: "Number is required"})}
                                                   label={"Card Number"} error={Boolean(errors.number)}/>
                                        <FormHelperText id="outlined-weight-helper-text"
                                                        sx={{color: "red"}}>{errors.number?.message}</FormHelperText>
                                        <Grid container sx={{mt: 1}} spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-currency"
                                                    select
                                                    sx={{pr: 1}}
                                                    label="Month"
                                                    {...register("month", {
                                                        required: {
                                                            value: true, message: 'Month is required'
                                                        }
                                                    })}
                                                    error={Boolean(errors.month)}
                                                    helperText={errors.month?.message}>
                                                    {month.map((option) => (
                                                        <MenuItem key={option.label} value={option.label}>
                                                            {option.short}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {/*<FormHelperText id="outlined-weight-helper-text"*/}
                                                {/*                sx={{color: "red"}}>{errors.month?.message}</FormHelperText>*/}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-currency"
                                                    select
                                                    label="Year"
                                                    {...register("year", {
                                                        required: {
                                                            value: true, message: 'Year is required'
                                                        }
                                                    })}
                                                    error={Boolean(errors.year)}
                                                    helperText={errors.year?.message}>
                                                    {year.map((option) => (
                                                        <MenuItem key={option.label} value={option.label}>
                                                            {option.year}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.year?.message}</FormHelperText>
                                            </Grid>
                                        </Grid>
                                        <TextField
                                            fullWidth {...register("securityCode", {required: "Security code is required"})}
                                            sx={{mt: 2}} error={Boolean(errors.number)}
                                            label={"Security Code"}/>
                                        <FormHelperText id="outlined-weight-helper-text"
                                                        sx={{color: "red"}}>{errors.securityCode?.message}</FormHelperText>
                                        <Grid container sx={{mt: 1}} spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <MobileDatePicker
                                                            label="Date mobile"
                                                            //inputFormat="MM/dd/yyyy"
                                                            value={date}
                                                            onChange={handleDate}
                                                            renderInput={(params) => <TextField fullWidth {...params} />}

                                                        />

                                                    </Stack>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth {...register("numberOfPayments", {required: "Number of Payment is required"})}
                                                    label={"Number Of Payments"} error={Boolean(errors.number)}/>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.year?.message}</FormHelperText>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{mt: 1}} spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth {...register("every", {required: "Every is required", min : {
                                                            value : 0, message : 'Every can not ber negetive'
                                                        }},
                                                    )}
                                                    type="number" label={"Every"} error={Boolean(errors.number)}/>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.every?.message}</FormHelperText>
                                                {errors.year && <span>This field is required</span>}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {/*<FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        //value={age}
                                                        label="Age"
                                                        onChange={handleUnit}
                                                    >
                                                        <MenuItem value={'DAY'}>Day</MenuItem>
                                                        <MenuItem value={'YEAR'}>Year</MenuItem>
                                                        <MenuItem value={'MONTH'}>Month</MenuItem>
                                                    </Select>
                                                </FormControl>*/}
                                                <TextField
                                                    select
                                                    fullWidth
                                                    label="Select"
                                                    inputProps={register('unit', {
                                                        required: 'Unit is required',
                                                    })}
                                                    error={Boolean(errors.unit)}
                                                    onChange={handleUnit}
                                                >
                                                    <MenuItem value={'DAY'}>Day</MenuItem>
                                                    <MenuItem value={'YEAR'}>Year</MenuItem>
                                                    <MenuItem value={'MONTH'}>Month</MenuItem>
                                                </TextField>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.unit?.message}</FormHelperText>
                                                {errors.year && <span>This field is required</span>}
                                            </Grid>
                                        </Grid>

                                        <Button fullWidth type={'submit'} variant={'outlined'} sx={{mt: 2}}>
                                            Submit
                                        </Button>
                                        {loading && (
                                            <LoadingAnimation/>
                                        )}
                                    </form>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}
export default DemoSchedule