import {
    Box,
    Button, Checkbox, Collapse,
    Container,
    Divider, FormControlLabel, FormGroup, FormHelperText,
    Grid, MenuItem,
    Modal,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useForm} from "react-hook-form";
import {API, Auth} from "aws-amplify";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import LoadingAnimation from "../../components/LoadingAnimation";
import ConfirmationModal from "./ConfirmationModal";
import {currencies, month, year} from "../../assets/month";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as React from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Notification from "../notification/notification";
import {listUsers} from "../../graphql/queries";

type FormValues = {
    merchantID: string,
    currency: number,
    amount: number,
    number: string,
    month: string,
    year: string,
    securityCode: string,
    custom: string,
    paymentTitle: string,
    paymentDescription: string,
    cardHolderName: string,
    name: string,
    birthdate: string,
    phone: string,
    mobilePhone: string,
    email: string,
    address1: string,
    address2: string,
    city: string,
    region: string,
    company: string,
    postalCode: string,
    country: string,
    paymentEmail: string,
    paymentNumber: string
};

interface userInfos {
    list: Array<{
        label: string;
        id: string
    }>
}


const OtherWebsite = () => {

    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState({
        username: null
    })


    const [flag, setFlag] = useState(false)
    const handleClose = () => setFlag(false);
    const [conformation, setConformation] = useState(false)
    const [confirmData, setConformData] = useState({})
    const [transactionID, setTransactionID] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [iframeCode, setIframeCode] = useState(null)
    const [message, setMessage] = useState('')
    const [notification, setNotification] = useState(false)
    const [type, setType] = useState('success')
    let [date, setDate] = (useState<Date | null>(
        new Date()
    ))

    let getData = async () => {
        const response: any = await API.graphql({
            query: listUsers,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {filter: {isVerified: {eq: true}}}
        })

        console.log('response', response.data)
        console.log('response', response.data)
        return response.data.listUsers.items
    }

    const [allUser, setAllUser] = useState<userInfos>({
        list: []
    })

    useEffect(() => {
        getData().then(r => {
            console.log("rrr", r)
            let array: { label: string, id: string }[] = []
            r.map((data: { fullName: any; id: any }) => array.push({
                label: `${data.fullName} (${data.id})`,
                id: data.id
            }))
            setAllUser({list: [...array]})
        })
    }, [])

    console.log("allUser", allUser)

    const handleDate = (newValue: Date | null) => {
        setDate(newValue);
    };

    const showModal = () => {
        setLoading(false)
        setIsModalVisible(true);
    };

    const [open, setOpen] = useState(false)

    const [checked, setChecked] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    console.log('isModalVisible', isModalVisible)

    useEffect(() => {
        if (transactionID) {
            setConformation(true)
        }

    }, [transactionID])

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])
    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
        watch,
        clearErrors
    } = useForm<FormValues>();
    const paymentNumber = useRef({});
    const paymentEmail = useRef({});


    paymentNumber.current = watch("paymentNumber");
    paymentEmail.current = watch("paymentEmail");

    useEffect(() => {
        if (paymentNumber.current !== '' || paymentEmail.current !== '') {
            clearErrors('paymentNumber')
            clearErrors('paymentEmail')
        }
    }, [paymentNumber.current, paymentEmail.current])
    console.log('hello world', paymentNumber.current === '', paymentEmail.current === '', paymentEmail.current)


    // const watchFields = watch(["paymentEmail", "paymentNumber"]);
    // useEffect(() => {
    //     const subscription = watch((value, { name, type }) => console.log(value, name, type));
    //     console.log('watch',subscription)
    //     return () => subscription.unsubscribe();
    // }, [watchFields])]);

    const onSubmit = async (data: any) => {

        // data.paymentEmail === undefined && data.paymentNumber === undefined && setError('custom', {
        //     type: 'custom',
        //     message: 'Credit Card Information error'
        // })

        let submitData = {
            body: {
                ...data,
                userID: data.merchantID,
                paymentEmail: data?.paymentEmail,
                requestType: "PaymentCardSaleTransaction",
                paymentNumber: data?.paymentNumber,
                merchantID: data.merchantID,
                methodNotificationURL: checked ? `${process.env.API_PATH}/Payments/` : null,
                authenticationType: checked ? process.env.AUTHENTICATION_TYPE : null,
                order: {
                    orderId: Math.floor((Math.random() * 100000000000) + 1),
                    billing: {
                        name: data.name,
                        customerId: Math.floor((Math.random() * 100000000000) + 1),
                        birthdate: null,
                        contact: {
                            phone: data.phone,
                            mobilePhone: data.mobilePhone,
                            email: data.email,
                        },
                        address: {
                            company: data.company,
                            address1: data.address1,
                            address2: data.address2,
                            city: data.city,
                            region: data.region,
                            postalCode: data.postalCode,
                            country: data.country
                        }
                    }
                }
            }
        }
        console.log(submitData)
        setLoading(true)
        await API.post(
            "paymentsgateway",
            `/Payments/`,
            submitData
        ).then(async (res: {
            authenticationResponse: any;
            transactionStatus: any;
            data: any; ipgTransactionId: any;
        }) => {
            setLoading(false)
            await setConformData({
                ...res
            })
            console.log('res', res)
            if (res.transactionStatus === "APPROVED") {
                setType('success')
                setNotification(true)
                setMessage('Payment successfully')
            } else {
                await setTransactionID(res?.ipgTransactionId)
                await setIframeCode(res?.authenticationResponse?.secure3dMethod.methodForm)
            }
            console.log('aws-data', confirmData)
        }).catch(errors => {
            console.log('errors', errors)
            setType('error')
            setMessage(`Payment failed : ${errors.message}`)
            setNotification(true)
            showModal()
            setError('custom', {type: 'custom', message: 'Credit Card Information error'})
            console.log('1:::', errors);
        });
    }

    console.log('iframeCode', iframeCode);
    console.log('check', checked)

    function handleDown() {
        setOpen(!open)
    }

    return (
        <Paper elevation={3} sx={{p: 2}}>
            {iframeCode && (
                <div
                    dangerouslySetInnerHTML={{__html: iframeCode}}
                />
            )}
            {loading && (
                <LoadingAnimation/>
            )}
            {
                conformation && (<ConfirmationModal data={confirmData}/>)
            }
            <Container>
                {Notification(message, notification, setNotification, type)}

                <Typography variant={'h4'} textAlign={"center"}>
                    Payment
                </Typography>
                <Divider sx={{m: 2}}/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant={'h5'}>
                                Description
                                <Stack spacing={2} sx={{mt: 2}}>
                                    <TextField {...register('paymentTitle')} label={'Short Description'}/>
                                    <TextField multiline {...register('paymentDescription')} rows={4}
                                               label={'Long Description'}/>
                                </Stack>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ml: 2}}>
                                <Paper sx={{p: 2}}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12}>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-currency"
                                                select
                                                sx={{pr: 1}}
                                                label="Send to"
                                                {...register("merchantID", {
                                                    required: {
                                                        value: true, message: 'this field is required'
                                                    }
                                                })}
                                                error={Boolean(errors.merchantID)}
                                                helperText={errors.merchantID?.message}>
                                                {allUser.list.map((option) => (
                                                    <MenuItem key={option.label} value={option.id}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-currency"
                                                sx={{pr: 1}}
                                                label="Payment Email"
                                                {...register("paymentEmail", {
                                                    required:
                                                        paymentEmail.current === '' && paymentNumber.current === '' ? "Phone number or email is required" : false,
                                                    pattern: {
                                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                                        message: "Email address is not valid"
                                                    },
                                                    // validate: value =>
                                                    //     paymentEmail.current === '' &&  paymentEmail.current === '' && "Phone number or email is required"
                                                })}
                                                error={Boolean(errors.paymentEmail)}
                                                helperText={errors.paymentEmail?.message}>
                                            </TextField>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-currency"
                                                sx={{pr: 1}}
                                                label="Payment Number"
                                                {...register("paymentNumber", {
                                                    required:
                                                        paymentEmail.current === '' && paymentNumber.current === '' ? "Phone number or email is required" : false

                                                })}
                                                error={Boolean(errors.paymentNumber)}
                                                helperText={errors.paymentNumber?.message}>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Typography variant={"h5"} sx={{mt: 2}}>
                                        Credit Card Info
                                    </Typography>
                                    <Box sx={{mt: 2}}>

                                        <Stack spacing={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField fullWidth label={"Price"}

                                                               {...register("amount", {
                                                                   required: {
                                                                       value: true, message: 'Price is required'
                                                                   },
                                                                   min: {
                                                                       value: 0, message: 'Amount can not ber negetive'
                                                                   }
                                                               })} type={"number"}
                                                               error={Boolean(errors.amount)}
                                                               helperText={errors.amount?.message}/>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Currency"

                                                        {...register("currency", {
                                                            required: {
                                                                value: true, message: 'Currency is required'
                                                            }
                                                        })}
                                                        error={Boolean(errors.currency)}
                                                        helperText={errors.currency?.message}

                                                    >
                                                        {currencies.map((option: any) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <TextField fullWidth
                                                           label={'Card Holders Name'} {...register('cardHolderName')}/>
                                            </Grid>
                                            <Grid container>
                                                <Grid xs={12}>
                                                    <TextField fullWidth {...register("number", {
                                                        required: {
                                                            value: true, message: 'Card Number is required'
                                                        }
                                                    })}
                                                               label={"Card Number"}
                                                               error={Boolean(errors.number)}
                                                               helperText={errors.number?.message}/>
                                                    {/*{errors.number && <span>This field is required</span>}*/}
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-select-currency"
                                                        select
                                                        sx={{pr: 1}}
                                                        label="Month"
                                                        {...register("month", {
                                                            required: {
                                                                value: true, message: 'Month is required'
                                                            }
                                                        })}
                                                        error={Boolean(errors.month)}
                                                        helperText={errors.month?.message}>
                                                        {month.map((option) => (
                                                            <MenuItem key={option.label} value={option.label}>
                                                                {option.short}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>

                                                </Grid>
                                                <Grid item xs={12} sm={6} sx={{pl: 1}}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Year"
                                                        {...register("year", {
                                                            required: {
                                                                value: true, message: 'Year is required'
                                                            }
                                                        })}
                                                        error={Boolean(errors.year)}
                                                        helperText={errors.year?.message}>
                                                        {year.map((option) => (
                                                            <MenuItem key={option.label} value={option.label}>
                                                                {option.year}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>

                                            </Grid>
                                            <TextField fullWidth {...register("securityCode", {
                                                required: {
                                                    value: true, message: 'Security Code is required'
                                                }
                                            })}
                                                       error={Boolean(errors.securityCode)}
                                                       helperText={errors.securityCode?.message}
                                                       label={"Security Code"}/>
                                            <Stack direction={"row"}>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => {
                                                        handleDown()
                                                    }}
                                                >
                                                    {open ? <KeyboardArrowUpIcon/> :
                                                        <KeyboardArrowDownIcon/>}
                                                </IconButton>
                                                <Typography sx={{mt: .5}}>
                                                    Add Billing Info
                                                </Typography>
                                            </Stack>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <Stack spacing={2}>
                                                    <TextField label={"Name"} {...register('name')} fullWidth/>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack spacing={3}>
                                                            <MobileDatePicker
                                                                label="Birth date"
                                                                //inputFormat="MM/dd/yyyy"
                                                                value={date}
                                                                onChange={handleDate}
                                                                renderInput={(params) => <TextField
                                                                    fullWidth {...params} />}

                                                            />

                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <Grid container>
                                                        <Grid item sm={6} sx={{pr: 1}}> <TextField
                                                            label={"Phone"} {...register('phone')} fullWidth/></Grid>
                                                        <Grid item sx={{pl: 1}} sm={6}> <TextField
                                                            label={"Mobile Phone"} {...register('mobilePhone')}
                                                            fullWidth/></Grid>
                                                    </Grid>
                                                    <TextField label={"Email"} {...register('email')} fullWidth/>
                                                    <TextField label={"Company"} {...register('company')} fullWidth/>
                                                    <TextField label={"Address1"} {...register('address1')} fullWidth/>
                                                    <TextField label={"Address2"} {...register('address2')} fullWidth/>
                                                    <Grid container>
                                                        <Grid sx={{pr: 1}} item sm={6}> <TextField
                                                            label={"City"} {...register('city')} fullWidth/></Grid>
                                                        <Grid sx={{pl: 1}} item sm={6}> <TextField
                                                            label={"Region"} {...register('region')} fullWidth/></Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid sx={{pr: 1}} item sm={6}> <TextField
                                                            label={"Postal Code"} {...register('postalCode')}
                                                            fullWidth/></Grid>
                                                        <Grid sx={{pl: 1}} item sm={6}> <TextField
                                                            label={"Country"} {...register('country')}
                                                            fullWidth/></Grid>
                                                    </Grid>
                                                </Stack>
                                            </Collapse>
                                            <Grid xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={checked} onChange={handleChange}/>}
                                                        label="Enable 3D Security"/>
                                                </FormGroup>
                                            </Grid>
                                            <Button fullWidth type={'submit'} variant={'outlined'}>
                                                Submit
                                            </Button>
                                            {
                                                errors.custom && (
                                                    <>

                                                        <FormHelperText sx={{color: "red"}} id="component-helper-text">
                                                            <Typography variant={'h6'} align={'center'}>
                                                                {errors.custom?.message}
                                                            </Typography>
                                                        </FormHelperText>
                                                    </>
                                                )
                                            }
                                        </Stack>
                                    </Box>
                                </Paper>

                            </Box>


                        </Grid>
                    </Grid>
                </form>
            </Container>
            <Modal
                open={flag}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Payment Status
                    </Typography>
                    <Typography variant="h2" id="modal-modal-description" sx={{mt: 2}}>
                        Payment Complete
                    </Typography>
                </Box>
            </Modal>
        </Paper>
    )
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default OtherWebsite